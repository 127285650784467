import React from 'react'

export default function Banner({ heading, para, buttonTitle, buttonHref, bannerId }) {
    return (
        <section id={bannerId}>
            <div class="apply">
                <div class="container">
                    {
                        !!heading &&
                        <h2 style={{ color: "white !important" }}>{heading}</h2>
                    }
                    {
                        !!para &&
                        <p style={{ color: "white !important" }}>{para}</p>
                    }

                    {
                        !!buttonTitle &&
                        <div class="applybtn">
                            <a href={buttonHref}>
                                <button>{buttonTitle}</button>
                            </a>
                        </div>
                    }

                </div>
            </div>
        </section>
    )
}
