import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import CallIcon from '@material-ui/icons/Call'
import LocationIcon from '@material-ui/icons/LocationOn'

const useStyles = makeStyles(theme => ({
    container: {
        boxShadow: "0px 8px 24px rgba(42, 106, 226, 0.1)",
        borderRadius: "12px",
        // height: "100%",
        flex: 1,
        padding: "20px"
    },
    links: {
        textDecoration: 'none'
    }
}))

function LocationComponent({ address }) {
    return <Grid container spacing={1} style={{ flexWrap: 'nowrap' }}>
        <Grid item style={{ display: 'flex' }}><LocationIcon color='primary' /></Grid>
        <Grid item><Typography variant='body2' color='textPrimary' style={{ whiteSpace: 'pre-line' }}>{address}</Typography></Grid>
    </Grid>
}

function TeamCard({ data }) {
    const { address, location, contactEmail, contactPhone1, contactPhone2, googleEmbedURL } = data
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <Grid container spacing={3}  >
                {
                    !!location &&
                    <Grid item xs={12}>
                        <Typography variant='h4' component='p' color='textPrimary'>{location}</Typography>
                    </Grid>
                }
                {
                    !!contactEmail &&
                    <Grid item xs={12}>
                        <a href={`mailto:${contactEmail}`} className={classes.links}>
                            <Grid container spacing={1} alignItems='center' style={{ flexWrap: 'nowrap' }}>
                                <Grid item style={{ display: 'flex' }}><EmailIcon color='primary' /></Grid>
                                <Grid item><Typography variant='body2' color='textPrimary'>{contactEmail}</Typography></Grid>
                            </Grid>
                        </a>
                    </Grid>
                }
                {
                    (!!contactPhone1 || !!contactPhone2) &&
                    <Grid item xs={12}>
                        <Grid container spacing={1} style={{ flexWrap: 'nowrap' }} >
                            <Grid item style={{ display: 'flex' }}><CallIcon color='primary' /></Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    {
                                        !!contactPhone1 &&
                                        <Grid item xs={12}>
                                            <a href={`tel:${contactPhone1}`} className={classes.links}>
                                                <Typography variant='body2' color='textPrimary'>{contactPhone1}</Typography>
                                            </a>
                                        </Grid>
                                    }
                                    {
                                        !!contactPhone2 &&
                                        <Grid item xs={12}>
                                            <a href={`tel:${contactPhone2}`} className={classes.links}>
                                                <Typography variant='body2' color='textPrimary'>{contactPhone2}</Typography>
                                            </a>
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    !!address &&
                    <Grid item xs={12}>
                        {
                            !!googleEmbedURL ?
                                <a href={googleEmbedURL} className={classes.links} target='_blank'>
                                    <LocationComponent address={address} />
                                </a> :
                                <LocationComponent address={address} />
                        }
                    </Grid>
                }
            </Grid>
        </div>
    )
}


export default TeamCard