import React from 'react'
import Layout from '../components/Layout'
import Hero from "../components/Hero"
import { graphql } from 'gatsby'
import { returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import RichTextComponent from '../components/RichTextComponent'
import { makeStyles } from '@material-ui/core'
import SectionTitleComponent from '../components/SectionTitleComponent'
import TeamCards from '../components/About/Teams'
import ClientsList from '../components/ClientsList'
import LocationCards from '../components/About/Locations'
import Banner from '../components/About/Banner'
import generalCss from '../styles/generalCss'
import Routes from '../utilities/Routes'
import { contactFormId } from '../utilities/Configurations'

const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "60px 50px"
    }
  }
}))

function About({ data }) {
  const classes = useStyles()
  const aboutPageContent = data.cms.brsAboutContent
  const heroData = returnTitleSubHeadingsAndImages(aboutPageContent)
  const content = !!aboutPageContent.content ? aboutPageContent.content : ''
  const teams = !!data.cms.brsTeams ? data.cms.brsTeams : []
  const clientsList = data.cms.brsClientsLists.length !== 0 ? data.cms.brsClientsLists[0].clientImages : []
  const companyLocations = !!data.cms.brsCompanyLocations ? data.cms.brsCompanyLocations : []
  const SEOData = !!aboutPageContent.SEO ? aboutPageContent.SEO : {}
  return (
    <div>
      <Layout pageSeo={SEOData}>
        <Hero
          title={heroData.title}
          subHeadings={[{ id: "sub-heading", value: heroData.subHeading }]}
          coverImageMobile={heroData.coverImageMobile}
          coverImageWeb={heroData.coverImageWeb}
          buttonTitle='Get an Estimate'
          buttonHref={`${Routes.about}/#${contactFormId}`}
        />
        <div className='mainContainer'>
          {
            !!content &&
            <section id="content">
              <RichTextComponent text={content}
                textClassName={classes.container}
                bannerHref={`${Routes.about}/#${contactFormId}`}
              />
            </section>
          }
        </div>
        {
          teams.length !== 0 &&
          <SectionTitleComponent
            sectionId='teams'
            title="Meet Our"
            coloredTitle='Core Team'
            backgroundColor={generalCss.sectionBlueBg}
          >
            <TeamCards cardList={teams} />
          </SectionTitleComponent>


        }
        {
          companyLocations.length !== 0 &&
          <SectionTitleComponent
            sectionId='locations'
            title="Our"
            coloredTitle='Company Locations'

          >
            <LocationCards cardList={companyLocations} />
          </SectionTitleComponent>
        }
        <div className='mainContainer'>
          <Banner
            heading='Want to find out more about Black Rook and how it can help with your business needs?'
            buttonTitle="Contact Us"
            buttonHref={`${Routes.about}/#${contactFormId}`}
          />
        </div>
        {
          clientsList.length !== 0 &&
          <SectionTitleComponent
            sectionId='clients'
            title='Join our list of clients'>
            <ClientsList
              clipList={true}
              clientList={clientsList}
            />
          </SectionTitleComponent>
        }
        <div className='mainContainer'>
          <Banner
            heading="Jump onto filling the below estimate form, and let our team experts call you back."
            buttonTitle='Get an Estimate'
            buttonHref={`${Routes.about}/#${contactFormId}`}
          />
        </div>

      </Layout>
    </div>
  )
}

export const query = graphql`
query AboutPageQuery {
    cms {
      brsAboutContent {
        SEO {
          SCHEMA_TAG
          SEO_DESCRIPTION
          SEO_TITLE
        }
        id
        content
        heading
        showTeam
        subHeading
        coverImageWeb {
          url
          alternativeText
        }
        coverImageMobile {
          alternativeText
          url
        }
      }
      brsTeams {
        designation
        id
        memberName
        profilePicture {
          alternativeText
          url
        }
        profileURL
        shortIntroduction
      }
      brsClientsLists {
        id
        clientImages {
          id
          clientImage {
            alternativeText
            url
          }
        }
      }
      brsCompanyLocations {
        address
        contactEmail
        contactPhone2
        contactPhone1
        id
        location
        googleEmbedURL
      }
    }
  }
  
`

export default About